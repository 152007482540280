import { gql } from 'graphql-tag'
import { fragmentModuleCompanyFormulaItem, fragmentModuleVideo, fragmentModuleInternalVideo } from '../../../core/data-layer/modules/fragments'
import queryPageFields from '../../../core/data-layer/pages/page/query-page-fields'

export default gql`
  ${fragmentModuleVideo}
  ${fragmentModuleInternalVideo}
  ${fragmentModuleCompanyFormulaItem}
  query($slug: String!, $locale: String!, $preview: Boolean!) {
    page: pageCareersFormulaCollection(limit: 1, where: { slug: $slug }, locale: $locale, preview: $preview) {
      items {
        ${queryPageFields}
        description
        formulaItemsTitle
        media {
          ...on ModuleVideo {
            ...ModuleVideoFragment
          }
          ... on ModuleInternalVideo {
            ...ModuleInternalVideoFragment
          }
        }
        formulaItemsCollection {
          items {
            ...ModuleCompanyFormulaItemFragment
          }
        }
      }
    }
  }
`
